import React, { Component } from "react";
import '../features.css';

export class features extends Component {

  render() {
    return (
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-12  feature-title">
            <h2>Featured Deals!</h2>
            <h4 className="discounts">NO MORE ADDED FEES!</h4>
          </div>
          {/* <div className="row row-space">
            <div className="col-xs-12 col-md-12">
              <img style={{ padding: '15px' }} src="img/boyne.png" className="boyne-img fit-img" alt="golf-course" />
              <hr className="boyne-divider " />
              <h3>Lesson On Golf Getaway!</h3>
              <div className="row">
                <a href="https://boynegolf.com/jefflessonpackage"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn btn-custom btn-lg">
                  Book Now
                </a>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-xs-6 col-md-6">
              <img src="img/black-lake-logo.png" className="course fit-img" alt="golf-course" />
              <h3>Black Lake Golf Club</h3>
              <h4 className="discounts">
                Twosome 2024
              </h4>
              <div className="row">
                <a href="https://lessongolf.myshopify.com/collections/frontpage/products/black-lake-golf-club-twosome-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn btn-custom btn-lg">
                  View Deal
                </a>
              </div>
            </div>
            <div className="col-xs-6 col-md-6">
              <img src="img/shanty.png" className="course" alt="golf-course" />
              <h3>Cedar River Golf Club</h3>
              <h4 className="discounts">
                Twosome 2024
              </h4>
              <div className="row">
                <a href="https://lessongolf.myshopify.com/collections/frontpage/products/cedar-river-golf-club-at-shanty-creek-twosome-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn btn-custom btn-lg">
                  View Deal
                </a>
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-xs-6 col-md-6">
              <img src="img/cattails.jpg" className="course" alt="golf-course" />
              <h3>Cattails Golf Club</h3>
              <h4 className="discounts">
                Twosome 2024
              </h4>
              <div className="row">
                <a href="https://lessongolf.myshopify.com/collections/frontpage/products/cattails-golf-club-twosome-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn btn-custom btn-lg">
                  View Deal
                </a>
              </div>
            </div>
            <div className="col-xs-6 col-md-6">
              <img src="img/hidden-river.png" className="course" alt="golf-course" />
              <h3>Hidden River Golf Club</h3>
              <h4 className="discounts">
                Twosome 2024
              </h4>
              <div className="row">
                <a href="https://lessongolf.myshopify.com/collections/frontpage/products/hidden-river-golf-club-2024-twosome-not-valid-weekends-before-11am"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn btn-custom btn-lg">
                  View Deal
                </a>
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-xs-6 col-md-6">
              <img src="img/hilltop.png" className="course fit-img" alt="golf-course" />
              <h3>Hilltop Golf Course</h3>
              <h4 className="discounts">
                Twosome 2024
              </h4>
              <div className="row">
                <a href="https://lessongolf.myshopify.com/collections/frontpage/products/hilltop-golf-course-twosome-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn btn-custom btn-lg">
                  View Deal
                </a>
              </div>
            </div>
            <div className="col-xs-6 col-md-6">
              <img src="img/moose.jpg" className="course fit-img" alt="golf-course" />
              <h3>Moose Ridge Golf Course</h3>
              <h4 className="discounts">
                Twosome 2024
              </h4>
              <div className="row">
                <a href="https://lessongolf.myshopify.com/collections/frontpage/products/moose-ridge-golf-course-no-holidays-twosome-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn btn-custom btn-lg">
                  View Deal
                </a>
              </div>
            </div>
          </div>
          <div className="row row-space deals-center full-width" >
            <a href="https://lessongolf.myshopify.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn btn-custom shop-btn-features btn-lg">
              View All Deals
            </a>
          </div >
          {/* {this.props.data
              ? this.props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
              : "Loading..."} */}
        </div >
      </div >
    );
  }
}

export default features;
