import React, { Component } from "react";
import '../navigation.css';

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll title" href="#page-top">
              <img src="./img/old-logo.png" alt="logo" style={{
                height: "50px",
                position: "absolute",
                top: "13px",
                left: "20px"
              }} />
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="https://lessongolf.myshopify.com/" target="_blank"
                  rel="noopener noreferrer" className="page-scroll">
                  shop
                </a>
              </li>
              <li>
                <a href="#features" className="page-scroll">
                  Featured Deals
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                  Twitter
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                  About
                </a>
              </li>
              {/* <li>
                <a href="#testimonials" className="page-scroll">
                  Testimonials
                </a>
              </li> */}
              <li>
                <a href="#sponsors" className="page-scroll">
                  Sponsors
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <a href="https://lessongolf.myshopify.com/" className="float-btn" target="_blank" rel="noopener noreferrer">
          <i class="fa fa-shopping-cart"></i>
        </a> */}
      </nav>
    );
  }
}

export default Navigation;
