import React, { Component } from "react";
import '../team.css'

export class Team extends Component {
  render() {
    return (
      <div id="sponsors" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Sponsors</h2>
            <p>
              Download our sponsorship opportunity PDF's:
            </p>
            <div className="links">
              <div>
                <i className="fa fa-download pdf-icon"></i>
                <a className="pdf" target="_blank" rel="noopener noreferrer" href="./pdf/ad-pdf-1.pdf">Ad Agency Marketing Opprotunities</a>
              </div>
              <div>
                <i className="fa fa-download pdf-icon"></i>
                <a className="pdf" target="_blank" rel="noopener noreferrer" href="./pdf/golf-course.pdf">Golf Course Marketing Opprotunities</a>
              </div>
            </div>
            <div className="row col-md-8 col-md-offset-2 learn-more">
              <h4>To learn more about our sponsorship opportunities, please contact Jeff Lesson by <a className="pdf" href="#contact">clicking here.</a></h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
