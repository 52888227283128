import React, { Component, } from "react";
import '../services.css'
export class Services extends Component {

  componentDidMount() {
    const anchor = document.createElement("a");
    anchor.setAttribute("class", "twitter-timeline");
    anchor.setAttribute("data-theme", "dark");
    anchor.setAttribute("data-tweet-limit", "5");
    anchor.setAttribute("data-chrome", "noheader nofooter noborders");
    anchor.setAttribute("href", "https://twitter.com/jefflesson");
    document.getElementsByClassName("twitter-embed")[0].appendChild(anchor);

    const script = document.createElement("script");
    script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }

  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Latest Tweets</h2>
            <a className="big-follow" style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer" href="https://twitter.com/JeffLesson?ref_src=twsrc%5Etfw">See More</a>
            <section className="twitterContainer">
              <div className="twitter-embed"></div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
