import React, { Component } from "react";
import '../header.css'
import '../features.css';

export class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVideoLoaded: false
    }
    this.videoLoad = this.videoLoad.bind(this);
  }
  componentDidMount() {
    document.getElementById('myVideo').play();
  }

  videoLoad(e) {
    this.setState({ isVideoLoaded: true })
  }

  render() {
    const { isVideoLoaded } = this.state;
    return (
      <header id="header" className="header-container">
        <div className="bg-overlay"></div>
        <a href="https://www.iwgcc.com"
          target="_blank"
          rel="noopener noreferrer"
          className="btn main-btn btn-move btn btn-custom shop-btn btn-lg hidden-sm hidden-md hidden-lg hidden-xl">
          <div className="row row-space">
            <img src="img/indianwood.jpg" className="boyne-img fit-img" alt="golf-course" />
            <hr className="boyne-divider " />
            <h4 className="wrap-text" style={{ textWrap: 'wrap' }}>Our featured course </h4>
            <p>Indianwood C.C.</p>
            <div className="row">
              <button
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn btn-custom btn-lg  main-book-btn">
                Check It Out Here
              </button>
              <hr />
              <a href="https://lessongolf.myshopify.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn btn-custom shop-btn-features btn-lg">
                View All Deals
              </a>
            </div>
          </div>
        </a>
        <a href="https://www.iwgcc.com"
          target="_blank"
          rel="noopener noreferrer"
          className="btn main-btn btn-move btn btn-custom shop-btn btn-lg  hidden-xs">
          <div className="row row-space">
            <img src="img/indianwood.jpg" className="boyne-img fit-img" alt="golf-course" />
            <hr className="boyne-divider " />
            <h3 className="wrap-text" style={{ textWrap: 'wrap' }}>Our featured course</h3>
            <p>Indianwood C.C.</p>
            <div className="row btn-container-main">
              <button
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn btn-custom btn-lg main-book-btn">
                Check It Out Here
              </button>
              <hr />
              <a href="https://lessongolf.myshopify.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn deals-main btn btn-custom shop-btn-features btn-lg">
                View All Deals
              </a>
            </div>
          </div>
        </a>
        {/* <div className="row row-space main-btn-deals deals-center full-width" >
          <a href="https://lessongolf.myshopify.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn btn-custom shop-btn-features btn-lg">
            View All Deals
          </a>
        </div > */}
        {/* <img className="main-logo" src="img/new-logo.png" alt="main logo" /> */}
        <video className={`${isVideoLoaded ? 'post-header-enhanced' : 'hidden'}`} onLoadedData={() => this.videoLoad()} autoPlay playsInline muted width="100%" height="100%" id="myVideo">
          <source src="videos/logv3.mp4" type="video/mp4" />
          Any alternate text.
        </video>
        <div className={`post-header ${isVideoLoaded ? 'hidden' : ""}`}>
        </div>
        {/* <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    Lesson On Golf
                    <span></span>
                  </h1>
                  <p>
                    The best that Golf Has
                  </p>
                  <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </header >
    );
  }
}

export default Header;
