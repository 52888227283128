import React, { Component } from "react";
import axios from 'axios';

export class Contact extends Component {

  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.test = this.test.bind(this);
  }

  test(e) {
    // debugger;
    // axios({
    //   method: 'post',
    //   url: '/mail',
    //   data: {
    //     firstName: 'Finn',
    //     lastName: 'Williams'
    //   }
    // });
  }

  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button id="sendMsg" type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <img className="img-responsive" src="./img/new-logo.png" alt="Lesson on golf logo" />
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  lessonongolfdeals@gmail.com
                </p>
              </div>
              <p>
                Links:
              </p>
              <ul>
                <li>
                  <a className="link" target="_blank"
                    rel="noopener noreferrer" href="https://www.carlsgolfland.com/">
                    Carls Golf Land
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    {/* <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li> */}
                    <li>
                      <a href="https://twitter.com/intent/follow?original_referer=http%3A%2F%2Fwww.lessonongolf.com%2F&ref_src=twsrc%5Etfw&screen_name=JeffLesson&tw_p=followbutton">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href={this.props.data ? this.props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="row">

              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2022 Designed by <a target="_blank" rel="noopener noreferrer" href="https://www.protocoding.com">protocoding.com</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
