import React, { Component } from 'react'

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <img src="img/about-pic.png" className="img-responsive" alt="" />
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Jeff Lesson</h2>
                <p>Jeff Lesson is a radio and television sportscaster, newspaper columnist and host of the award winning show "Lesson On Golf"
                featuring the best in places to play, pro pointers, equipment, travel, fitness
                and half-off golf at LESSONONGOLF.COM!
                </p>
                <p>Lesson On Golf Sundays 8-9 am on 97.1 The Ticket March 28- August 15,
                  and also at 97.1 The Ticket or Radio.com</p>
                {/* <h3>Accomplishments</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data ? this.props.data.Why.map((d, i) => <li key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data ? this.props.data.Why2.map((d, i) => <li key={`${d}-${i}`}> {d}</li>) : 'loading'}

                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about
